// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SignInForm from "./signInForm";
import { CreateAccount } from "./signInForm";
import VerifyOTP from "./verifyOtp";
import { SignInUser } from "types";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useHistory, useLocation } from "react-router-dom";
import "./style.css";
import "components/Account/style.css";
import "components/Layout/SiteContent/Support/style.css";
import "styles/common.css";
import "components/Layout/SiteContent/MyOrders/style.css";
import "components/Layout/Header/style.css";
import snack from "components/wrapper/snack";
import { useDispatch } from "react-redux";
import { appleloginStart, googleloginStart } from "redux/user";
import { FcGoogle } from "react-icons/fc";
import AppleIcon from "@mui/icons-material/Apple";
import { GoogleLogin } from "react-google-login";
import FirebaseTypes from "firebase";
import { firebaseForApple as firebase } from "../../../../config/firebase.ts";
import { Logo } from "assets2/images/images";

import {
  GoogleButton,
  AppleButton,
  AuthContainer,
  IconContainer,
  LineWithOr,
  OrText,
  LogoAndTitleStyles,
  BrandLogoLink,
  BrandLogoImg,
  GoogleAuthContainerDiv,
  LoginWrapperDiv,
  SignupHeader,
} from "./styledComponents";
import SEO from "../ExploreProductsPage/SEO/SEO";

const SignIn: React.FC = ({ fromPopper }) => {
  const userData: any = useSelector((state: RootState) => state.user);
  const location: any = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [msg, setMsg] = useState("");
  const [user, setUser] = useState<SignInUser>({
    emailOrPhone: "",
    password: "",
    phoneNumber: "",
    countryCode: "",
    is2FAVerified: false,
  });

  // If route is visited with google or apple param, then automatically
  // redirect to google or apple login
  // URL should look like this: https://paysfer.com/signin?method=google
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const signInProvider = urlParams.get("method");
    console.log(signInProvider);

    // Ensure that the buttons are rendered before clicking them
    setTimeout(() => {
      if (signInProvider === "google") {
        const googleButton = document.getElementById("googleSignInButton");
        googleButton?.click();
      }

      if (signInProvider === "apple") {
        const appleButton = document.getElementById("appleSignInButton");
        appleButton?.click();
      }
    }, 2000);
  });

  useEffect(() => {
    if (location.state && location.state.msg) {
      setMsg(location.state.msg);
    }

    if (
      localStorage.getItem("access-token") &&
      localStorage.getItem("access-token") !== null
    ) {
      history.push("/");
    }
  }, []);

  const responseFromGoogle = (response: any) => {
    const token = response["tokenId"];
    if (location.state && location.state.buyNowFlow) {
      dispatch(
        googleloginStart({
          token,
          history,
          buyNowFlow: location.state.buyNowFlow,
          redirect: location.state?.redirect,
        })
      );
    } else
      dispatch(
        googleloginStart({ token, history, redirect: location.state?.redirect })
      );
  };

  const errorFromGoogle = (response: any) => {
    snack.error("Google Sign in failed");
    history.push("/");
  };

  const signInWithApple = async () => {
    var provider = new FirebaseTypes.auth.OAuthProvider("apple.com");
    provider.addScope("name");
    provider.addScope("email");
    try {
      const response = await firebase.auth().signInWithPopup(provider);
      console.log(response);
      if (response && response.user?.email) {
        if (location.state && location.state.buyNowFlow) {
          dispatch(
            appleloginStart({
              response,
              history,
              buyNowFlow: location.state.buyNowFlow,
              redirect: location.state?.redirect,
            })
          );
        } else {
          dispatch(
            appleloginStart({
              response,
              history,
              redirect: location.state?.redirect,
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <SEO
        title="Secure Sign In - Access Your Paysfer Account"
        description="Sign in to your Paysfer account securely. Access your orders, manage preferences, and enjoy seamless shopping with ease."
        keywords="Paysfer Sign In, Login Paysfer, Secure Login, Account Access, Paysfer Account, Online Shopping Login"
        url="https://www.paysfer.com/signin"
        image="https://www.paysfer.com/assets/signin-banner.jpg"
        siteName="Paysfer"
      />
      <Grid direction="column" container alignItems="center">
        {!fromPopper ? (
          <Grid
            item
            xs={12}
            style={{
              width: "90%",
            }}
          >
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid item>
                <div
                  style={{
                    background: "black",
                    padding: "10px 15px",
                    borderRadius: "4px",
                  }}
                >
                  <img
                    src={Logo}
                    alt="paysfer-logo"
                    style={{
                      width: "auto",
                      height: "30px",
                    }}
                    onClick={() => history.push("/")}
                    loading="lazy"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        <div className="signInWrapper">
          <>
            {/* Sign in Form */}
            <Grid item>
              {!userData.verified ? (
                <>
                  {msg ? (
                    <div className={"verifyEmailMsg"}>
                      <p>{msg}</p>
                    </div>
                  ) : null}
                  <SignInForm user={user} setUser={setUser} />
                </>
              ) : (
                <VerifyOTP user={userData.user} />
              )}
            </Grid>

            <LineWithOr>
              <OrText>or</OrText>
            </LineWithOr>
          </>

          {/* Sign In with Google / Apple */}
          <Grid item>
            <AuthContainer>
              <GoogleAuthContainerDiv className="googleAuthContainer">
                {/* Google Sign in */}
                <LoginWrapperDiv className="googleAndAppleLoginWrapper">
                  <GoogleLogin
                    clientId="337705274204-gac97kappr7imkhg32ijarfdgv0jfp8o.apps.googleusercontent.com"
                    onSuccess={responseFromGoogle}
                    onFailure={errorFromGoogle}
                    cookiePolicy={"single_host_origin"}
                    render={(renderProps) => (
                      <GoogleButton
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="authProviderText"
                        id="googleSignInButton"
                      >
                        <IconContainer>
                          <FcGoogle className="authProviderIcon" />
                        </IconContainer>
                        Sign in with Google
                      </GoogleButton>
                    )}
                  />
                </LoginWrapperDiv>

                {/* Apple Sign In */}
                <LoginWrapperDiv className="googleAndAppleLoginWrapper">
                  <AppleButton
                    onClick={signInWithApple}
                    className="authProviderText"
                    id="appleSignInButton"
                  >
                    <IconContainer>
                      <AppleIcon className="authProviderIcon" />
                    </IconContainer>
                    Sign in with Apple
                  </AppleButton>
                </LoginWrapperDiv>
              </GoogleAuthContainerDiv>
            </AuthContainer>
          </Grid>
          <LineWithOr>
            <OrText>or</OrText>
          </LineWithOr>
          {/* Create account */}
          <Grid item>
            <CreateAccount
              createAccountClick={() => history.push("/users/buyer/register")}
            />
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default SignIn;
