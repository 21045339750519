import { Helmet } from "react-helmet";
import MetaTags from "react-meta-tags";
import React from "react";
import { totalmem } from "os";

type SEOProps = {
  title: string;
  description: string;
  keywords?: string;
  url: string;
  image: string;
  type?: string;
  siteName?: string;
  subshop?: boolean;
};

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  keywords,
  url,
  image,
  type,
  siteName,
  subshop,
}) => {
  // Default meta tags
  let metaTitle = title;
  let metaDescription = description;
  let metaKeywords = keywords;
  let metaImage = image;

  // Conditional meta tags based on category
  if (title === "Motor Vehicle Frame & Body Parts") {
    metaTitle = "Motor Vehicle Frame and Body Parts | Paysfer";
    metaDescription =
      "Explore high-quality motor vehicle frame and body parts at Paysfer. Our extensive selection ensures durability and performance for your vehicle. Shop now for reliable parts that meet your needs!";
    metaKeywords = "motor, vehicle, frame, body, parts";
  } else if (title === "Anklets") {
    metaTitle =
      "Shop Stylish Anklets at Paysfer - Unique Designs for Every Occasion ";
    metaDescription =
      "Discover a stunning collection of anklets at Paysfer. Explore unique designs crafted for every style and occasion. Shop now for quality anklets that elevate your look!";
    metaKeywords = "anklets, jewelry, fashion anklets";
  } else if (title === "Jumpsuits & Rompers") {
    metaTitle = "Jumpsuits & Rompers";
    metaDescription =
      "Discover a trendy collection of jumpsuits & romper at  Paysfer. Shop  now for stylish designs that blend comfort. Enjoy fast shipping & secure payment options.";
  } else if (title === "Coats & Jackets") {
    metaTitle = "Online Store Stylish Coats and Jackets | Paysfer";
    metaDescription =
      "At Paysfer a wide selection of fashionable coats & jackets. Explore for the latest trends in outerwear, designed for comfort & style. Shop now to elevate your wardrobe!";
  } else if (title === "Watches") {
    metaTitle = "Online Shop Stylish Watches Collections | Paysfer";
    metaDescription =
      "Paysfer provide a wide range of latest watches . Shop now for the latest designs that combine quality & style, perfect for any occasion. Enjoy secure shopping & fast delivery!";
  } else if (title === "Perfume & Cologne") {
    metaTitle = "Perfume & Cologne";
    metaDescription =
      "Looking a luxurious selection of perfumes & colognes at Paysfer. Explore brands and unique scents to find your signature fragrance. Enjoy quality & elegance.";
  } else if (title === "Boots") {
    metaTitle = "Online Shop Stylish Boots Collections | Paysfer";
    metaDescription =
      "Discover our collection of Stylish boots Collections at Paysfer. Elevate your style with our trendy scarves. Shop now & add the perfect finishing touch to your outfit!";
  } else if (title == "Scarves") {
    metaTitle = "Top Stylish Scarves for Women | Paysfer | Elevate Your Look";
    metaDescription =
      "Discover our collection of fashionable scarves at Paysfer. Elevate your style with our trendy scarves. Shop now & add the perfect finishing touch to your outfit!";
  } else if (title == "Brooches & Lapel Pins") {
    metaTitle = "Top Stylish Brooches & Lapel Pins Store | Paysfer ";
    metaDescription =
      "Discover our collection of Stylish Brooches & Lapel Pins store at Paysfer. Elevate your style with our trendy brooches and pins. Shop now & add the perfect finishing touch to your outfit!";
  } else {
    if (!subshop) {
      metaTitle = `Explore ${title} at Paysfer eMart`;
      metaDescription = `Discover the best products in our ${title} category. Shop now for great deals on a wide range of ${title} items.`;
      metaKeywords = `${title}, ${title} products, best ${title} deals`;
    }
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        <link rel="canonical" href={url} />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content={type || "website"} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={metaImage} />
        <meta
          property="og:site_name"
          content={siteName || "Default Site Name"}
        />
        <meta property="og:locale" content="en_US" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: metaTitle,
            description: metaDescription,
            url: url,
            image: metaImage,
            author: {
              "@type": "Organization",
              name: siteName || "Default Site Name",
            },
          })}
        </script>
      </Helmet>
      <MetaTags>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={url} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content={type || "website"} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:site_name" content={siteName || "Paysfer eMart"} />
        <meta property="og:locale" content="en_US" />
      </MetaTags>
    </>
  );
};

export default SEO;
