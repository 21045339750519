import React, { useEffect, useState } from "react";
import "./priceAnimation.css";

interface AnimatedPriceProps {
  salePrice: number;
  price: number;
  maxQty?: number;
}

const AnimatedPrice: React.FC<AnimatedPriceProps> = ({ salePrice, price }) => {
  const [showSale, setShowSale] = useState(false);

  useEffect(() => {
    if (salePrice && price > salePrice) {
      const timer = setTimeout(() => {
        setShowSale(true);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [salePrice, price]);

  if (salePrice && price > salePrice) {
    const discountPercent = (((price - salePrice) / price) * 100).toFixed(0);

    return (
      <div className="price-inline">
        <span className="original-price">${price.toFixed(2)}</span>
        <span className={`sale-price ${showSale ? "animate-sale" : ""}`}>
          ${salePrice.toFixed(2)}
        </span>
        <span className="discount-percent">({discountPercent}% off)</span>
      </div>
    );
  }

  return (
    <div className="price-inline">
      <span className="normal-price">${price.toFixed(2)}</span>
    </div>
  );
};

export default AnimatedPrice;
